.login-block-auth {
    .link-login-partner,
    .link-forget,
    .form-field-name {
        font-size: 13px;
    }

    .login-title {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.login-block-registration {
    padding-left: 6px;
    padding-right: 6px;
}
